import React, { useEffect, useState, useRef } from "react";
import "@aarsteinmedia/dotlottie-player";
import { Link } from "react-router-dom";
import Badges from '../../main/Badges'
import { pathToLottieAnimation } from "../utils";
import fontColorContrast from 'font-color-contrast'

const SmallBlockType8 = ({ data, url_list }) => {
  const animationBg = data.animation ? data.animation.background : 0; // 0

  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (data?.animation) handleLottieAnimationLoad()
  }, [data?.animation]);

  const handleLottieAnimationLoad = () => {
    // const lottieFileURL = data?.animation ? `/lottie/${data.animation.url}` : null;
    const lottieFileURL = data?.animation ? `${pathToLottieAnimation}${data.animation.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current) {
      waitForLottieAnimationLoaded().catch((err) => {
        setAnimationURL(null)
      })
    }
  }, [playerRef.current]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }

  return (

    <Link
      to={data.link_button ? data.link_button.replace(
        "https://galaktika.me",
        ""
      ) : '#'}
    >
      <div className="small-block small-block-8">
        <div
          className="small-block__overlay"
          style={{ position: "absolute" }}
        >
          <div className={`block7-animation  ${animationBg === 0 ? "fg" : "bg"}`}>
            {
              data.animation && animationURL !== null &&
              <dotlottie-player
                ref={playerRef}
                src={animationURL}
                background="transparent"
                speed={data.animation.speed}
                direction="1"
                mode="normal"
                loop
                autoplay
              ></dotlottie-player>
            }
          </div>
          {
            data.priceBadgeStock &&
            <Badges items={data.priceBadgeStock} />
          }
        </div>
        <div className="small-block-8-container">

          {data.photo_url && <img className="small-block__img" src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + data.photo_url} alt={''} />}
          <h3 title={data.name}>{data.name}</h3>

          <div>
            <div className="price">
              <div className="producst-price__wrap">

                {
                  data?.priceBadgeStock?.price?.discountPrice &&
                  <div className="products-price__old">
                    <span className="price i-rub d-inline-block">
                      {
                        data.priceBadgeStock.price?.price?.toLocaleString("ru")
                      }
                    </span>
                  </div>
                }
                <div className="products-price__main d-flex align-items-baseline">
                  {/* price-unit */}
                  <span className="price i-rub">
                    {
                      data?.priceBadgeStock?.price?.discountPrice &&
                      data.priceBadgeStock.price.discountPrice.toLocaleString("ru")
                    }
                    {
                      !data?.priceBadgeStock?.price?.discountPrice &&
                      data.priceBadgeStock.price.price &&
                      data.priceBadgeStock?.price?.price?.toLocaleString("ru")
                    }
                  </span>

                  {
                    data.unit &&
                    <div className='price-unit'>
                      <span>
                        /
                      </span>
                      <span>
                        {data.unit}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
            {
              data.motive_text &&
              <h5 className="motive-text my-2">
                {data.motive_text}
              </h5>
            }
            <div
              className="btn"
              style={{
                backgroundColor: data.color_button ? data.color_button : 'inherit',
                color: data.color_button ? fontColorContrast(data.color_button) : 'inherit',
              }}
            >
              {data.text_button}
            </div>

          </div>
        </div>
      </div>
    </Link>
  );
};

export default SmallBlockType8;
