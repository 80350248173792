import React, { useState, useEffect, useRef } from 'react'
import ReactImageMagnify from 'react-image-magnify';
import Slider from 'react-slick';
import { isIterableArray } from '../block-slider/utils';

import ReactImageVideoLightbox from 'react-image-video-lightbox'
import Badges from '../main/Badges';

const SingeProductPhotosDesktop = (props) => {

    const settings = {

        dots: true,
        dotsClass: 'desktop-dots-custom',
        appendDots: dots => {
            console.log(dots)
            return (
                <ul>{dots}</ul>
            )
        },
        // dotsClass: "slick-dots slick-thumb",
        infinite: false,
        speed: 200,
        slidesToShow: 10,
        slidesToScroll: 1,

        arrows: true,
        autoplay: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 1626,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            //   {
            //     breakpoint: 700,
            //     settings: {
            //       slidesToShow: 1,
            //     }
            //   }, 
        ]
    }


    const galleryContainerRef = useRef(null)
    const [computedHeight, setComputedHeight] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    const [currentImg, setCurrentImg] = useState('');
    const [imagesArray, setImagesArray] = useState([]);

    const [lightboxData, setLightboxData] = useState([]);


    useEffect(() => {
        console.log(props.images)
        if (props.images[0]) {
            setCurrentImg(props.images[0])
        }
    }, [props.images]);


    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.addEventListener('resize', handleResize)
        };
    }, [galleryContainerRef.current]);


    const handleResize = () => {
        if (galleryContainerRef?.current?.clientWidth && galleryContainerRef?.current?.clientWidth > 0) {
            if (galleryContainerRef.current.clientWidth > 550) {
                setComputedHeight(550)
            }
            else setComputedHeight(galleryContainerRef.current.clientWidth)
        }
        else {
            setComputedHeight(400)
        }
    }

    useEffect(() => {
        setImagesArray(props.images)

        const forLightbox = props.images.map(item => ({
            url: item.original,
            type: item.type,
            altTag: "",

        }))
        setLightboxData(forLightbox)
    }, [props.images]);

    const showBig = () => {
        setIsOpen(true)
        document.body.style.overflow = 'hidden'
        document.querySelector('.header-desktop').style.zIndex = 0
        document.querySelector('.header-top-menu').style.zIndex = 0
    }
    const closeBig = () => {
        setIsOpen(false)
        document.body.style.overflow = 'auto'
        document.querySelector('.header-desktop').style.zIndex = 100
        document.querySelector('.header-top-menu').style.zIndex = 102
    }

    const clickThumb = (e, n) => {
        setCurrentImgIndex(n)
        setCurrentImg(imagesArray[n])
        document.querySelectorAll('.thumb')[0].style.borderColor = '#aaa'
        e.target.style.borderColor = '#a07'
    }

    return (
        <div
            ref={galleryContainerRef}
            className='desktop-gallery'
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {
                isIterableArray(props.badges?.bages) &&
                <Badges items={props.badges} />
            }
            <div onClick={showBig} className='mb-3'>

                <div className='product-main__gallery-slide' style={{ height: computedHeight }}>
                    {
                        currentImg.type === 'photo' &&
                        <ReactImageMagnify {...{
                            smallImage: {
                                isFluidWidth: true,
                                alt: props.name + ' - изображение ' + (currentImgIndex + 1),
                                src: currentImg.thumbnail,
                                sizes: '400px',
                            },
                            largeImage: {
                                src: currentImg.original,
                                alt: props.name + ' - изображение ' + (currentImgIndex + 1),
                                width: 1000,
                                height: 1000
                            },
                            style: {
                                zIndex: 9999
                            }
                        }} />
                    }
                    {
                        currentImg.type === 'video' &&
                        <video src={currentImg.original} autoPlay muted controls loop playsInline />
                    }
                </div>

            </div>
            <div className='px-3'>
                <Slider {...settings}>
                    {imagesArray.map((img, i) => {
                        return (
                            <div key={i} className='thumb'
                                onClick={(e) => { clickThumb(e, i) }}>
                                {
                                    img.type === 'photo' ?
                                        <div style={{
                                            borderRadius: 3,
                                            border: (currentImgIndex === i) ? '1px solid #a07' : '1px solid #aaa',
                                        }}>
                                            <img src={img.thumbnail}
                                                alt={props.name + ' - изображение ' + (i + 1)}
                                                style={{
                                                    width: '100%',
                                                    borderRadius: 3,
                                                    transform: (currentImgIndex === i) ? 'scale(0.85)' : 'none',
                                                    // height: '100%',
                                                }}
                                            />
                                        </div>
                                        :
                                        img.type === 'video' ?
                                            <div
                                                className='thumb-video'
                                                style={{
                                                    borderRadius: 3,
                                                    border: (currentImgIndex === i) ? '1px solid #a07' : '1px solid #aaa'
                                                }}
                                            >
                                                <video src={img.original} muted controls={false} playsInline style={{
                                                    transform: (currentImgIndex === i) ? 'scale(0.85)' : 'none',
                                                }} />
                                            </div>
                                            :
                                            ''
                                }
                            </div>
                        )
                    })}
                </Slider>
            </div>

            {
                isOpen && isIterableArray(lightboxData) &&
                <div className='lightbox-container'>
                    <ReactImageVideoLightbox
                        data={lightboxData}
                        startIndex={currentImgIndex}
                        showResourceCount={true}
                        onCloseCallback={closeBig}
                    // onNavigationCallback={(currentIndex) =>
                    //     setCurrentImgIndex(currentIndex)
                    // }
                    />
                </div>
            }
        </div>
    )
}

export default SingeProductPhotosDesktop
