import React, { memo, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Slider from 'rc-slider';
import { isIterableArray } from '../../block-slider/utils';
import { storageFilters } from '../utils';
const Range = Slider.Range;

export const getRangeValue = filter => {

    const reg = /[^\d]/g;

    let str = ''
    if (isIterableArray(filter.filter_option)) {
        filter.filter_option.forEach(element => {
            str += element.filter_option_value
        });
    }
    const numbersArray = str.replaceAll(reg, ' ').split(reg)

    const rangeArray = numbersArray.filter(item => Number(item) > 0)
    // rangeArray - массив всех чисел 
    // console.log(rangeArray) 

    return {
        rangeArray: rangeArray,
        min: Number(rangeArray[0]),
        max: Number(rangeArray[rangeArray.length - 1]),
    }
}

const PageCategoryFilters = (props) => {
    const {
        // functions
        changePageAndHandle,
        toggleFilter,
        brandCheck,
        onLowerBoundChange,
        onUpperBoundChange,
        onSliderChange,
        filterCheck,
        rangeCheck,
        setSelectedRanges,


        // state
        manufacturers,
        filterToggleOn,
        price_to,
        price_from,
        min,
        max,
        filters,

        selectedManufacturers,
        selectedFilters,
        selectedRanges


    } = props

    const isCheckedBrand = id => {
        const storageFiltersData = localStorage.getItem(storageFilters) ? JSON.parse(localStorage.getItem(storageFilters)) : {}

        // console.log(storageFiltersData, selectedManufacturers)
        if (isIterableArray(selectedManufacturers)) {
            selectedManufacturers.forEach(element => {
                const selector = `#m` + element.replaceAll(' ', '')
                const input = document.querySelector(selector)
                if (input) input.checked = true
            })
        }
    }
    const isCheckedFilter = id => {
        // const storageFiltersData = localStorage.getItem(storageFilters) ? JSON.parse(localStorage.getItem(storageFilters)) : {}

        // console.log(storageFiltersData)
        if (isIterableArray(selectedFilters)) {
            // console.log(selectedFilters)
            selectedFilters.forEach(element => {
                // console.log(element)
                const selector = element ? (`#f` + String(element)?.replaceAll(' ', '')) : false
                const input = selector ? document.querySelector(selector) : false
                if (input) input.checked = true
            })
        }
    }

    useEffect(() => {
        isCheckedBrand()
    }, [selectedManufacturers])

    useEffect(() => {
        isCheckedFilter()
    }, [selectedFilters])

    // useEffect(() => {
    //     if (isIterableArray(filters)) isInRange(130)
    // }, [filters])


    const getOptionColorStyles = opt => {

        const imageStyles = {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: '0px !important',
            border: '1px solid',
            borderColor: opt.color?.border,
        }


        if (opt.color?.multicolor) return ({ backgroundImage: 'url(/img/multicolor.png)', ...imageStyles })
        if (opt.color?.nocolor) return ({ backgroundImage: 'url(/img/nocolor.jpg)', ...imageStyles })
        if (opt.color?.image_url) return ({
            backgroundImage: `url(${process.env.REACT_APP_BACKEND + 'uploads/products/categories_filters/' + opt.color.image_url})`,
            ...imageStyles
        })
        if (opt.color?.gradient) return ({ backgroundImage: opt.color.gradient, ...imageStyles })

        return {
            // backgroundColor: opt.color_value
            // border: opt.color_value
            // image_url: opt.color_value
            backgroundColor: opt.color.color,
            ...imageStyles
        }
    }


    // const isInRange = (value) => {

    //     const { min, max } = getRangeValue(filters[0])

    //     console.log(value > min, value < max)
    // }

    // const onRangeChange = (value, selector) => {
    //     // console.log(value)
    //     // console.log(document.querySelector(`${ selector } input[name = from]`))
    //     document.querySelector(`${selector} input[name = from]`).value = value[0]
    //     document.querySelector(`${selector} input[name = to]`).value = value[1]

    //     rangeCheck()

    // }


    const getRangeValue2 = (arr, filter_id) => {
        const rangeArray = arr.reduce((acc, item) => {
            item.filter_option_range.map(r_item => {
                return acc.push(r_item)
            })
            return acc
        }, [])
        // const defData = isIterableArray(selectedRanges) ? selectedRanges.find(item => item.filter_id == filter_id) : null
        // console.log({
        //     from: defData ? Number(defData.from) : Number(rangeArray[0]),
        //     to: defData ? Number(defData.to) : Number(rangeArray[rangeArray.length - 1]),
        //     rangeArray: rangeArray,
        //     min: Number(rangeArray[0]),
        //     max: Number(rangeArray[rangeArray.length - 1]),
        // }) 
        return {
            // from: defData ? Number(defData.from) : Number(rangeArray[0]),
            // to: defData ? Number(defData.to) : Number(rangeArray[rangeArray.length - 1]),
            rangeArray: rangeArray,
            min: Number(rangeArray[0]),
            max: Number(rangeArray[rangeArray.length - 1]),
        }
    }



    const handleRangeChange = ({ from, to, filter_id }) => {
        const newSelected = selectedRanges.filter(item => item.filter_id !== filter_id)
        newSelected.push({
            from: from,
            to: to,
            filter_id: filter_id
        })
        console.log(newSelected)
        setSelectedRanges(newSelected)
    }

    return (

        <div className="catalog-filter__inner">

            <div className="catalog-filter__footer flex top-button">
                <Button className="filter-set" onClick={changePageAndHandle}>Показать</Button>
            </div>
            {isIterableArray(manufacturers) &&
                <div className={`filter-block ${filterToggleOn ? '' : ''} `}>
                    <Button variant="link filter-block__title" onClick={toggleFilter}><i className="i-arrow-b" />
                        <h4>Производители</h4>
                    </Button>
                    <div className="filter-block__inner">
                        <div className="checkbox-filter">
                            {manufacturers.map((opt, index) => {
                                return (
                                    <div className="checkbox-filter__item" key={index}>
                                        <input
                                            className="custom-checkbox filter-check brand-check"
                                            type="checkbox"
                                            id={'m' + opt.id}
                                            onChange={brandCheck}
                                        // checked={isCheckedBrand(opt.id)}
                                        />
                                        <label htmlFor={'m' + opt.id}>
                                            {opt.name}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            {
                price_to !== null ?
                    <div className="filter-block">
                        <Button variant="link filter-block__title" onClick={toggleFilter}>
                            <i className="i-arrow-b" /><h4>Цена</h4></Button>
                        <div className="filter-block__inner">

                            <div className="price-range">
                                <div className="price-range__wrap">
                                    <label>от</label>
                                    <input type="number" value={price_from} onChange={onLowerBoundChange} />
                                    <label>до</label>
                                    <input type="number" value={price_to} onChange={onUpperBoundChange} />
                                    <i className="i-rub" />
                                    <Button variant="primary" onClick={changePageAndHandle}>OK</Button>
                                </div>
                                <Range value={[price_from, price_to]} min={min}
                                    max={max} allowCross={false} onChange={onSliderChange} />
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
            {
                isIterableArray(filters) &&
                filters.map((filter, index) => {

                    // 11 - высота
                    if (filter.type === 'range') {

                        const { min, max } = getRangeValue2(filter.filter_option)

                        const isrange = selectedRanges.find(item => item.filter_id == filter.filter_id)
                        const currentRange = isrange ? isrange : {
                            from: min,
                            to: max
                        }

                        return (
                            <div className="filter-block" key={index}>
                                <Button variant="link filter-block__title" onClick={toggleFilter}>
                                    <i className="i-arrow-b" />
                                    <h4>
                                        {filter.name}
                                    </h4>
                                </Button>
                                <div className="filter-block__inner">

                                    <div className={`price-range range-filter-input range-filter-input-${filter.filter_id}`}
                                        id={`filter_id_${filter.filter_id}`}
                                    >
                                        <div className="price-range__wrap">
                                            <label>от</label>
                                            <input
                                                type="number"
                                                value={currentRange.from}
                                                name={'from'}
                                                onChange={e => handleRangeChange({
                                                    from: e.target.value,
                                                    to: currentRange.to,
                                                    filter_id: filter.filter_id
                                                })}
                                            />
                                            <label>до</label>
                                            <input
                                                type="number"
                                                // defaultValue={to}
                                                value={currentRange.to}
                                                name={'to'}
                                                onChange={e => handleRangeChange({
                                                    to: e.target.value,
                                                    from: currentRange.from,
                                                    filter_id: filter.filter_id
                                                })}
                                            />
                                            <Button variant="primary" onClick={changePageAndHandle}>
                                                ОК
                                            </Button>
                                        </div>
                                        <Range
                                            min={min}
                                            max={max}
                                            allowCross={false}
                                            value={[currentRange.from, currentRange.to]}
                                            onChange={val => handleRangeChange({
                                                from: val[0],
                                                to: val[1],
                                                filter_id: filter.filter_id
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (filter.type === 'tile') return (
                        <div className="filter-block filter-block-tile" key={index}>
                            <Button variant="link filter-block__title" onClick={toggleFilter}>
                                <i className="i-arrow-b" />
                                <h4>
                                    {filter.name}
                                </h4>
                            </Button>
                            <div className="filter-block__inner">
                                <div className="tile-filter">
                                    {filter.filter_option.map((opt, f_index) => {
                                        return (
                                            <div key={f_index}>
                                                <div className="checkbox-filter__item">
                                                    <input
                                                        className="filter-check"
                                                        type="checkbox"
                                                        // id={opt.filter_option_id}
                                                        id={'f' + opt.filter_option_id}
                                                        // id={opt.filter_option_id}
                                                        onChange={() => filterCheck(opt)}
                                                    />
                                                    <label
                                                        htmlFor={'f' + opt.filter_option_id}
                                                        className={`tile-filter__label`}
                                                        title={opt.filter_option_value}
                                                        style={
                                                            opt.color ?
                                                                getOptionColorStyles(opt)
                                                                :
                                                                {
                                                                    outlineOffset: '-2px'
                                                                }
                                                        }

                                                    >
                                                        {
                                                            opt.color ? '' : opt.filter_option_value
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )

                    if (filter.type === 'checkbox') return (
                        <div className="filter-block" key={index}>
                            <Button variant="link filter-block__title" onClick={toggleFilter}>
                                <i className="i-arrow-b" />
                                <h4>
                                    {filter.name}
                                </h4>
                            </Button>
                            <div className="filter-block__inner">
                                <div className="checkbox-filter">
                                    {filter.filter_option.map((opt, index) => {
                                        return (
                                            <div className="checkbox-filter__item" key={index}>
                                                <input className="custom-checkbox filter-check"
                                                    type="checkbox"
                                                    // id={opt.filter_option_id}
                                                    id={'f' + opt.filter_option_id}
                                                    // id={opt.filter_option_id}
                                                    onChange={() => filterCheck(opt)} />
                                                <label htmlFor={'f' + opt.filter_option_id}>
                                                    {opt.filter_option_value}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div >
    )
}

export default PageCategoryFilters