// React 
import React, { Component, useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';

//My components
import HeaderCatalog from "./HeaderCatalog";
import MobileNav from "./MobileNav";
import HeaderLogo from "./HeaderLogo";
import HeaderPromoLine from "./HeaderPromoLine";
import HeaderTopLine from "./HeaderTopLine";
import HeaderSearch from "./HeaderSearch";
import HeaderProfileMenu from "./HeaderProfileMenu";

//SCSS
import '../../scss/components/main/header.scss';
import '../../scss/components/main/headerTopMenu.scss';
import '../../scss/components/main/headerSelectCenter.scss';

import axios from "axios";
import HeaderTopMenu from './menus/HeaderTopMenu';
import { Link } from 'react-router-dom';
import ModalSmall from './centerSelect/ModalSmall';
import ModalMain from './centerSelect/ModalMain';
import CartCtx from '../CartCtx';
import { isIterableArray } from '../block-slider/utils';

const Header = (props) => {

    const { selectedCenter, setSelectedCenter, tcData } = useContext(CartCtx)

    const [isToggleOn, setIsToggleOn] = useState(true);
    const [items, setItems] = useState([]);
    const [topBanner, setTopBanner] = useState(null);

    const [isModalSmall, setIsModalSmall] = useState(false); // модалка в углу
    const [isModalMain, setIsModalMain] = useState(false); // модалка выбора тц на весь экран

    const [userCords, setUserCords] = useState(null); // координаты пользователя (если есть)
    const [computedCenter, setComputedCenter] = useState(null); // ближайший тц (посчитано)


    // список всех тц из всех городов
    const [tcList, setTcList] = useState([]);


    const handleClick = () => {
        setIsToggleOn(prev => !prev)
    }


    // для промо-баннер
    const getLink = (linkObj) => {

        if (typeof linkObj === 'string') {
            if (linkObj.indexOf("https://galaktika.me") !== -1)
                return linkObj.replace("https://galaktika.me/", '/')
            if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
                return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
            return '/' + linkObj
        }

        if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

        if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
        if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
        if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/categories-button`)
            .then(response => {
                setItems(response?.data?.data?.[0] || [])
            })
            .catch(error => {
                console.error(error);
            })

        axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-index`)
            .then(response => {
                setTopBanner(response.data.data.baner)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        if (isIterableArray(tcData?.list)) {
            const all_tc_arr = tcData.list.reduce((acc, item) => {
                if (isIterableArray(item.ts_list)) item.ts_list.map(tc_item => {
                    acc.push(tc_item)
                })
                return acc
            }, [])
            setTcList(all_tc_arr)
        }
    }, [tcData]);

    useEffect(() => {
        if (!selectedCenter) {
            setIsModalSmall(true)
        }
        // else {
        //     const selTc = tcList.find(item => item.id == selectedCenter.id)
        //     setSelectedCenterState(selTc)
        // }
    }, [selectedCenter, tcList]);


    // получает координаты пользователя
    useEffect(() => {
        const getUserGeolocation = () => {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    setUserCords([position.coords.latitude, position.coords.longitude])
                }
            });
        }
        getUserGeolocation()
    }, [navigator.geolocation]);

    // выбираем тц с минимальным растоянием  
    useEffect(() => {
        // getTcDistance - берет все тц из массива городов. считает для каждого расстояние
        const getTcDistance = () => {
            const res = tcList.map(item => compute(item, userCords))
            return res
        }
        if (tcData && userCords) {
            const tcDistance = getTcDistance()
            const min = tcDistance.reduce((acc, item) => {
                if (!acc) acc = item
                if (item.value < acc.value) acc = item
                return acc
            });

            setComputedCenter(min.tc)
        }

    }, [tcList, userCords]);

    // считает коэф расстояния от тц до пользователя
    const compute = (tc, userCord) => {
        const [x0, y0] = tc.location
        const [x, y] = userCord

        const dist = Math.sqrt(Math.pow((x0 - x), 2) + Math.pow((y0 - y), 2))

        return {
            tc,
            value: dist
        }
    }


    const handleSelectCenter = (data) => {
        // setSelectedCenterState(data)
        setSelectedCenter(data)
        setIsModalMain(false)
        setIsModalSmall(false)
    }

    const handleOpenMainModal = () => {
        setIsModalSmall(false)
        setIsModalMain(true)
    }
    const closeSmallModal = () => {
        setIsModalSmall(false)

    }
    const closeMainModal = () => {
        setIsModalMain(false)

    }

    return (
        <>
            <div className='container flex-grow-0'>
                <ModalSmall
                    isModalSmall={isModalSmall}
                    computedCenter={computedCenter}
                    handleSelectCenter={handleSelectCenter}
                    handleOpenMainModal={handleOpenMainModal}
                    closeSmallModal={closeSmallModal}
                />
            </div>
            <ModalMain
                isModalMain={isModalMain}
                closeMainModal={closeMainModal}
                handleSelectCenter={handleSelectCenter}
            />
            <header className={`${topBanner ? 'with-top-banner' : ''}`}>
                {/* <HeaderPromoLine /> */}
                {
                    topBanner && (
                        <div className="header-promo pr-test">
                            <Link to={getLink(topBanner.link)} title="Акция">
                                <img src={`${process.env.REACT_APP_BACKEND}/uploads/sp/banners/${topBanner.baner_url}`} alt="" />
                            </Link>
                        </div>
                    )
                }

                <Container>
                    <div className="catalog-menu">
                        <div id={'menu-underlay'} className={`overlay_main ${isToggleOn ? '' : 'open'}`} onClick={handleClick}></div>
                        <div className={`header-catalog ${isToggleOn ? '' : 'open'}`} >
                            <HeaderCatalog items={items} />
                        </div>
                    </div>
                    <HeaderTopMenu
                        data={props.headerData}
                        handleOpenMainModal={handleOpenMainModal}
                    />
                    <div className='header-desktop'>
                        <Row noGutters className='align-items-end w-100'>
                            <HeaderLogo />
                            <Col className="header-menu">
                                {/* <HeaderTopLine /> */}
                                <Nav className='header-menu__bottom flex'>
                                    <div className="catalog-menu">
                                        <Button id='catalog-btn' className='catalog-btn' onClick={handleClick}>
                                            <h2 style={{ fontSize: 'inherit', margin: 0 }}>Каталог товаров</h2>
                                        </Button>

                                    </div>

                                    <HeaderSearch />
                                    <HeaderProfileMenu />

                                </Nav>
                            </Col>
                        </Row>
                    </div>
                </Container>

                <MobileNav
                    items={items}
                    headerData={props.headerData}
                    footerData={props.footerData}
                    handleOpenMainModal={handleOpenMainModal}
                />
            </header>
        </>
    )
}

export default Header;
