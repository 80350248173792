import React from 'react'
import Slider from 'react-slick';
import { isIterableArray } from '../block-slider/utils';
import Badges from '../main/Badges';

const SingeProductPhotos = (props) => {
    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    {
                        props.images[i]?.type === 'photo' &&
                        <img src={props.images[i].original} />
                    }
                    {
                        props.images[i]?.type === 'video' &&
                        <div
                            className='thumb-video'
                        >
                            <video src={props.images[i].original} muted controls={false} playsInline />
                        </div>
                    }
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb mobile-scrollbar",
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,

        arrows: true,
        autoplay: false,
        draggable: true,
        afterChange: (e) => {
            if (e > 4) {
                document.querySelector('.mobile-scrollbar').scrollTo({
                    top: 0,
                    left: 60 * e,
                    behavior: 'smooth'
                })
            }
            if (e === 0) {
                document.querySelector('.mobile-scrollbar').scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    }

    // const [photoIndex, setPhotoIndex] = useState(0);
    // const [isOpen, setIsOpen] = useState(false);
    // const [currentImg, setCurrentImg] = useState(props.images[0]);
    // const [currentImgIndex, setCurrentImgIndex] = useState(0);


    return (
        <div
            className='mobile-gallery'
            style={{
                display: 'flex',
                flexDirection: 'column'
            }} >

            {
                isIterableArray(props.badges?.bages) &&
                <Badges items={props.badges} />
            }

            <div className="slider-container">
                <Slider {...settings}>
                    {
                        isIterableArray(props.images) &&
                        props.images.map((img, key) => (
                            <div key={key} className='product-main__gallery-slide'>
                                {
                                    img.type === 'photo' &&
                                    <img src={img.thumbnail} />
                                }
                                {
                                    img.type === 'video' &&
                                    <video src={img.original} muted autoPlay controls loop playsInline />
                                }
                            </div>
                        ))
                    }
                </Slider>
            </div>

        </div >
    )
}

export default SingeProductPhotos
