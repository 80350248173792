import React, { useContext } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import CartCtx from '../../CartCtx'

const ModalSmall = ({ isModalSmall, computedCenter, handleSelectCenter, handleOpenMainModal, closeSmallModal }) => {

    const { tcData } = useContext(CartCtx)
 
    return (
        <div className={`center-select__modalSmall ${isModalSmall ? 'open' : ''}`}>
            {
                tcData &&
                <>
                    {
                        computedCenter ?
                            <>
                                <div className='center-select__modalSmall-text'>
                                    <h5>
                                        Ближайший торговый центр {computedCenter.address}
                                    </h5>
                                    <p>
                                        {tcData?.text}
                                    </p>
                                </div>
                                <div className='center-select__modalSmall-buttons'>
                                    <Button className='confirm-btn' onClick={() => {
                                        handleSelectCenter(computedCenter)
                                    }}>
                                        Да
                                    </Button>
                                    <Button variant='outline-primary' className='change-btn' onClick={handleOpenMainModal}>
                                        Изменить
                                    </Button>
                                </div>
                            </>
                            :
                            <>
                                <div className='center-select__modalSmall-text'>
                                    <h5>
                                        {tcData?.title}
                                    </h5>
                                    <p>
                                        {tcData?.text}
                                    </p>
                                </div>
                                <div className='center-select__modalSmall-buttons'>
                                    <Button className='confirm-btn' onClick={handleOpenMainModal}>
                                        Выбрать
                                    </Button>
                                    <Button variant='outline-primary' className='change-btn' onClick={closeSmallModal}>
                                        Закрыть
                                    </Button>
                                </div>
                            </>
                    }
                </>
                // :
                // <Spinner animation='border' className='mx-auto' style={{ color: '#0A1A5C' }} />
            }
        </div>
    )
}

export default ModalSmall
